<template>
  <v-container fluid class="pa-2">
    <v-row v-if="projects.length > 1" no-gutters>
      <v-col v-for="item in projects" :key="item.id" md="4" sm="6" cols="12">
        <v-card class="clickable ma-2" @click.native="goToProject(item.id)">
          <v-card-title class="text-h6">
            <v-row dense>
              <v-col sm="7" cols="12">
                {{ item.title }}
              </v-col>
              <v-col sm="5" cols="12" class="text-right">
                <v-tooltip v-if="item.issues_count > 0" bottom>
                  <template v-slot:activator="{ on }">
                    <v-chip v-on="on" class="ma-0" color="red" text-color="white" small @click.stop>
                      {{ item.issues_count }}
                    </v-chip>
                  </template>
                  <span>{{ $t('projects.tabs.issues') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-chip
                      v-on="on"
                      class="ma-0"
                      :color="getChipColorByStatus(item.status)"
                      text-color="white"
                      small
                      @click.stop
                    >
                      {{ $t(`projects.project_status.${item.status}`) }}
                    </v-chip>
                  </template>
                  <span>{{ $t('projects.project_status_label') }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <div v-for="sprint in item.sprints" :key="sprint.id">
              <div>{{ sprint.sprint_name }}. {{ sprint.starts_at }} - {{ sprint.deadline }}</div>
              <div>
                {{ $t('projects.general.completed_stories') }}:
                {{ sprint.completed_user_stories }}/{{ sprint.total_user_stories }}
              </div>
              <v-progress-linear
                :value="getSprintProgress(sprint.completed_points, sprint.total_points)"
                class="mt-1 mb-4"
                color="primary"
                height="4"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <h3 v-else-if="projects.length === 0 && !loading['get:api/projects']" class="text-h6 pa-3">
      {{ $t('projects.no_projects_are_assigned_to_you') }}.
    </h3>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import eventBus, { HIDE_TOP_PROGRESS_BAR, SHOW_TOP_PROGRESS_BAR } from '@/util/event-bus';

export default {
  name: 'ClientProjects',

  computed: {
    ...mapState('projects', ['projects']),
    ...mapGetters(['loading']),
  },

  async created() {
    eventBus.$emit(SHOW_TOP_PROGRESS_BAR);
    const { data } = await this.fetchProjects();
    if (data.length === 1) {
      this.goToProject(data[0].id);
    } else {
      eventBus.$emit(HIDE_TOP_PROGRESS_BAR);
    }
  },

  methods: {
    ...mapActions('projects', ['fetchProjects']),

    getSprintProgress(completed, total) {
      return (completed / total) * 100;
    },

    goToProject(projectId) {
      this.$router.push({ name: 'project', params: { projectId } });
    },

    getChipColorByStatus(status) {
      if (status === 'new' || status === 'planned') {
        return 'red';
      }
      if (status === 'active') {
        return 'primary';
      }
      return 'grey';
    },
  },
};
</script>
